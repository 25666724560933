// ForgotPassword.js
import React, { useState } from "react";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/constants";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/api/v0/users/password`, { email });

    enqueueSnackbar(
      "If the email exists, you will receive a password reset link.",
      { variant: "info" }
    );
    navigate("/login");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5",
        mt: 20,
      }}
    >
      <Box
        component="img"
        sx={{
          height: 250,
          borderRadius: "50%",
          mb: 5,
        }}
        alt="Key Sentry Logo"
        src="/notfound.png"
      />
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: "auto" }}>
        <Typography variant="h5" gutterBottom align="center">
          Forgot Password
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ForgotPassword;
