import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/user";

function AboutUsCTA({ scrollToContact }) {
  const user = useSelector(fetchUser);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showTooManyRequestsDialog, setShowTooManyRequestsDialog] =
    useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const featuresReady = process.env.REACT_APP_FEATURES_READY === "true";

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const [email, setEmail] = useState(""); // For waitlist email input
  const isLaunched = process.env.REACT_APP_LAUNCHED === "true";
  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${API_URL}/api/v0/waitlist`, { email });
      alert("Thank you for joining our waitlist!");
      setEmail(""); // Reset input after submission
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.status === 429) {
        setShowTooManyRequestsDialog(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (featuresReady) {
      setIsLoading(true);
      if (user) {
        return navigate("/dashboard");
      }
      try {
        const response = await axios.post(`${API_URL}/api/v0/keywords`, {
          userInput: inputValue,
        });
        navigate("/onboarding", {
          state: { trackingParameters: response.data.trackingParameters },
        });
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 429) {
          setShowTooManyRequestsDialog(true);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        minHeight: isMobile ? "50vh" : "65vh",
        backgroundImage: "url(/bg1.png)",
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          maxWidth: { sm: "90%", md: "75%", lg: "60%" },
          textAlign: "center",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 2,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Key Sentry
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Unlock Insights, Secure Success - We Listen to What Matters Most!
        </Typography>

        {!isLaunched ? (
          <form onSubmit={handleWaitlistSubmit}>
            <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
              Join our waitlist:
            </Typography>
            <TextField
              label="Your email"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ backgroundColor: "white", borderRadius: 1, marginRight: 1 }}
              error={email !== "" && !validateEmail(email)}
              helperText={
                email !== "" && !validateEmail(email)
                  ? "Enter a valid email"
                  : ""
              }
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                height: 54,
                marginTop: {
                  sm: 0,
                  xs: 2,
                },
                mb: 5,
              }}
              disabled={!validateEmail(email)}
            >
              Join Waitlist
            </Button>
          </form>
        ) : !user ? (
          <form onSubmit={handleSearch} style={{ marginBottom: 30 }}>
            <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
              Tell us what you're looking to monitor:
            </Typography>
            <TextField
              label="E.g., I want to track mentions of my brand on Reddit."
              helperText="Describe your monitoring needs in a sentence or two."
              variant="filled"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              sx={{ backgroundColor: "white", borderRadius: 1, marginRight: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                height: 54,
                marginTop: {
                  sm: 0,
                  xs: 5,
                },
              }}
            >
              Search
            </Button>
          </form>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              height: 54,
              marginTop: {
                sm: 0,
                xs: 5,
              },
              mb: 5,
            }}
            onClick={() => navigate("/dashboard")}
          >
            Go to Dashboard
          </Button>
        )}
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={scrollToContact}
          >
            Contact Us
          </Button>
        </Grid>
      </Box>
      <LoadingOverlay open={isLoading} />
      <Dialog
        open={showTooManyRequestsDialog}
        onClose={() => setShowTooManyRequestsDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Too Many Requests"}</DialogTitle>
        <DialogContent>
          Please slow down. You are sending too many requests too quickly.
          Please wait a few seconds before trying again.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowTooManyRequestsDialog(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AboutUsCTA;
