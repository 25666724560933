import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function UserInfo({ handleBack, handleNext, userInfo, setUserInfo }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    // Regular expression to enforce exactly 8 characters with at least one letter and one number
    const re = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    return re.test(password);
  };

  const canProceed = () =>
    userInfo.firstName &&
    userInfo.lastName &&
    validateEmail(userInfo.email) &&
    validatePassword(userInfo.password) &&
    userInfo.password === userInfo.confirmPassword;

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "90%", mx: "auto", px: 2 }}>
      <Typography variant="h6" gutterBottom>
        User Information
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="First Name"
              name="firstName"
              value={userInfo.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Last Name"
              name="lastName"
              value={userInfo.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Email"
              name="email"
              value={userInfo.email}
              onChange={handleChange}
              error={userInfo.email !== "" && !validateEmail(userInfo.email)}
              helperText={
                userInfo.email !== "" && !validateEmail(userInfo.email)
                  ? "Enter a valid email"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={userInfo.password}
              onChange={handleChange}
              error={
                userInfo.password !== "" && !validatePassword(userInfo.password)
              }
              helperText={
                userInfo.password !== "" && !validatePassword(userInfo.password)
                  ? "Password must be at least 8 characters with 1 number and 1 letter"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={userInfo.confirmPassword}
              onChange={handleChange}
              error={
                userInfo.confirmPassword !== "" &&
                userInfo.password !== userInfo.confirmPassword
              }
              helperText={
                userInfo.confirmPassword !== "" &&
                userInfo.password !== userInfo.confirmPassword
                  ? "Passwords must match"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button variant="outlined" color="primary" onClick={handleBack} sx={{}}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={!canProceed()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default UserInfo;
