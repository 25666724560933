import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { API_URL } from "../utils/constants";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { confirmEmailSuccess, fetchUser } from "../redux/actions/user";

function VerifyEmail() {
  const user = useSelector(fetchUser);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${API_URL}/api/v0/email?token=${token}`
        );
        const data = await response.data;
        if (data && response.status === 200) {
          setIsTokenValid(true);
          dispatch(confirmEmailSuccess());
          setIsLoading(false);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsTokenValid(false);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setIsLoading(false);
    }
  }, [token, dispatch]);

  const requestNewToken = async () => {
    setIsLoading(true);
    try {
      await axios.post(`${API_URL}/api/v0/email`, {
        email: user.email,
      });
      enqueueSnackbar("Email sent!", {
        variant: "success",
        autoHideDuration: 5000,
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        "Failed re-sending email. Try again later or contact support!",
        {
          variant: "success",
          autoHideDuration: 5000,
        }
      );
    }
    setIsLoading(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5",
        mt: 20,
      }}
    >
      <Box
        component="img"
        sx={{
          height: 250,
          borderRadius: "25%",
        }}
        alt="Key Sentry Logo"
        src="/logo2.png"
      />
      {!token ? (
        <>
          <h1>Verification Required</h1>
          <p>
            An email containing your confirmation link has been sent to your
            inbox.
          </p>
          <Button variant="contained" onClick={requestNewToken}>
            Request New Verification Email
          </Button>
        </>
      ) : isTokenValid ? (
        <>
          <h1>Email Verified Successfully!</h1>
          <p>You can now access the dashboard.</p>
          <Button variant="contained" onClick={() => navigate("/dashboard")}>
            Go to Dashboard
          </Button>
        </>
      ) : (
        <>
          <h1>Verification Failed</h1>
          <p>The verification link is invalid or expired.</p>
          <Button variant="contained" onClick={requestNewToken}>
            Request New Verification Email
          </Button>
        </>
      )}
      <LoadingOverlay open={isLoading} />
    </Box>
  );
}

export default VerifyEmail;
