import React from "react";
import { Box, CircularProgress } from "@mui/material";

function LoadingOverlay({ open }) {
  if (!open) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9999, // High z-index to make sure it covers everything
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingOverlay;
