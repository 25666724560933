import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Chip,
  Stack,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";

const KeywordManagementCard = ({
  user,
  updateUserKeywords,
  monitoredKeywords,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [newKeyword, setNewKeyword] = useState("");
  const keywordLimit = {
    starter: 1,
    basic: 1,
    professional: 5,
    business: Infinity,
  };

  const canAddMoreKeywords = () => {
    const currentKeywordCount =
      user && user.trackingParameters && user.trackingParameters.keywords
        ? Object.keys(user.trackingParameters.keywords).length
        : 0;
    const subscriptionType = user.subscription || "basic";

    return currentKeywordCount < keywordLimit[subscriptionType];
  };

  const canUpdate = () => {
    const subscriptionType = user.subscription || "basic";

    if (subscriptionType === "starter") {
      const lastUpdate = new Date(user.trackingParameters.lastKeywordUpdate);
      const now = new Date();
      const diffHours = Math.abs(now - lastUpdate) / 36e5;
      const updatesToday = user.trackingParameters.updatesToday || 0;

      if (diffHours < 24 && updatesToday >= 2) {
        return true;
      }
    }
  };

  const handleAddKeyword = () => {
    if (
      newKeyword &&
      (!user.trackingParameters.keywords ||
        !user.trackingParameters.keywords[newKeyword])
    ) {
      if (canAddMoreKeywords()) {
        const updatedKeywords = {
          ...user.trackingParameters.keywords,
          [newKeyword]: { count: 0 },
        };
        updateUserKeywords(updatedKeywords);
        setNewKeyword("");
      }
    } else if (user.trackingParameters.keywords[newKeyword]) {
      enqueueSnackbar("Keyword already exists", {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    const updatedKeywords = { ...user.trackingParameters.keywords };
    delete updatedKeywords[keywordToRemove];
    updateUserKeywords(updatedKeywords);
  };

  const isAddButtonDisabled = canAddMoreKeywords() && newKeyword;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Manage Keywords
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        sx={{ mb: 2, flexWrap: "wrap" }}
      >
        {monitoredKeywords === 0 ? (
          <Typography sx={{ textAlign: "center", color: "#666" }}>
            No keywords available.
          </Typography>
        ) : (
          Object.keys(user.trackingParameters.keywords).map(
            (keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                disabled={
                  canUpdate() &&
                  !canAddMoreKeywords() &&
                  user.subscription === "starter"
                }
                onDelete={() => handleRemoveKeyword(keyword)}
                color="primary"
                sx={{
                  "&&": {
                    mt: 2,
                  },
                }}
              />
            )
          )
        )}
      </Stack>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          label="New Keyword"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          sx={{ mr: 1 }}
        />
        <Tooltip
          title={
            !canAddMoreKeywords()
              ? user.subscription === "starter" && !canAddMoreKeywords()
                ? "As a Starter plan user, you can only update your keywords once every 24 hours."
                : `You have reached the maximum number of keywords for your ${user.subscription} subscription.`
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              onClick={handleAddKeyword}
              disabled={!isAddButtonDisabled}
            >
              Add
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Paper>
  );
};

export default KeywordManagementCard;
