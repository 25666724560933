import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeywordsDisplay from "./KeywordsDisplay";
import { useLocation, useNavigate } from "react-router-dom";
import UserInfo from "./UserInfo";
import PaymentPlanOptions from "./PaymentPlanOptions";
import ReviewAndConfirm from "./ReviewAndConfirm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/actions/user";
import LoadingOverlay from "../../components/LoadingOverlay";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function Onboarding() {
  const steps = [
    "Select Keywords",
    "User Info",
    "Payment Plan Options",
    "Review & Confirm",
  ];
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedKeywords, setSelectedKeywords] = useState(() => {
    const savedData = localStorage.getItem("onboardingData");
    return savedData
      ? JSON.parse(savedData).selectedKeywords
      : { keywords: [], ignoreKeywords: [] };
  });
  const [billingCycle, setBillingCycle] = useState(() => {
    const savedData = localStorage.getItem("onboardingData");
    return savedData ? JSON.parse(savedData).billingCycle : "monthly";
  });

  const [userInfo, setUserInfo] = useState(() => {
    const savedData = localStorage.getItem("onboardingData");
    return savedData
      ? JSON.parse(savedData).userInfo
      : {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        };
  });
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const savedData = localStorage.getItem("onboardingData");
    return savedData ? JSON.parse(savedData).selectedPlan : "professional";
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState(() => {
    const savedData = localStorage.getItem("onboardingData");
    return savedData ? JSON.parse(savedData).selectedPlatforms : [];
  });

  const location = useLocation();
  const initializeTrackingParameters = () => {
    const savedData = localStorage.getItem("onboardingData");
    if (savedData) {
      const savedTrackingParams = JSON.parse(savedData).selectedKeywords;
      return location.state && location.state.trackingParameters
        ? {
            keywords: [
              ...savedTrackingParams.keywords,
              ...location.state.trackingParameters.keywords,
            ],
            ignoreKeywords: [
              ...savedTrackingParams.ignoreKeywords,
              ...location.state.trackingParameters.ignoreKeywords,
            ],
          }
        : savedTrackingParams;
    }

    if (location.state && location.state.trackingParameters) {
      return location.state.trackingParameters;
    }

    return { keywords: [], ignoreKeywords: [] };
  };
  const [trackingParameters, setTrackingParameters] = useState(
    initializeTrackingParameters
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    localStorage.setItem(
      "onboardingData",
      JSON.stringify({
        selectedKeywords,
        userInfo,
        selectedPlan,
        selectedPlatforms,
        billingCycle,
      })
    );
  }, [
    selectedKeywords,
    userInfo,
    selectedPlan,
    selectedPlatforms,
    billingCycle,
  ]);

  useEffect(() => {
    if (user && user.paid) navigate("/dashboard");
  });

  const handlePaymentSuccess = useCallback(async () => {
    if (!user) return;
    try {
      const payload = {
        userId: user._id,
        paid: true,
      };

      const response = await axios.put(`${API_URL}/api/v0/users/`, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        localStorage.removeItem("onboardingData");
        localStorage.removeItem("user");
        setIsLoading(false);
        const updatedUserData = await axios.get(`${API_URL}/api/v0/users/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });

        dispatch(loginSuccess(updatedUserData.data));
        navigate("/verify-email");
      } else {
        console.log("else...fix");
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      setIsLoading(false);
    }
  }, [dispatch, user, navigate]);

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const session_id = params.get("session_id");
      if (!session_id) {
        return;
      }

      try {
        const response = await axios.get(
          `${API_URL}/api/v0/stripe/payment?session_id=${session_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          handlePaymentSuccess();
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        setIsLoading(false);
      }
    };

    if (user) {
      verifyPayment();
    }
  }, [location, navigate, handlePaymentSuccess, user]);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);

      const registerResponse = await axios.post(
        `${API_URL}/api/v0/users/`,
        {
          ...userInfo,
          trackingParameters: selectedKeywords,
          selectedPlan,
          selectedPlatforms,
          billingCycle,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (registerResponse.data.user) {
        localStorage.setItem(
          "user",
          JSON.stringify(registerResponse.data.user)
        );
        setUser(registerResponse.data.user);

        const response = await axios.get(
          `${API_URL}/api/v0/stripe/intent?plan=${selectedPlan}&billingCycle=${billingCycle}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const sessionId = response.data.id;
        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      console.error("Error redirecting to Stripe Checkout:", error);
      setIsLoading(false);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <KeywordsDisplay
            handleNext={handleNext}
            handleBack={handleBack}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
            trackingParameters={trackingParameters}
            setTrackingParameters={setTrackingParameters}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
          />
        );
      case 1:
        return (
          <UserInfo
            handleNext={handleNext}
            handleBack={handleBack}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        );
      case 2:
        return (
          <PaymentPlanOptions
            handleNext={handleNext}
            handleBack={handleBack}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            billingCycle={billingCycle}
            setBillingCycle={setBillingCycle}
            selectedKeywordsLength={selectedKeywords.keywords.length}
          />
        );
      case 3:
        return (
          <ReviewAndConfirm
            selectedKeywords={selectedKeywords}
            userInfo={userInfo}
            selectedPlan={selectedPlan}
            handleBack={handleBack}
            handleConfirm={handleConfirm}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        color: "#000",
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 3, pb: 5 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          alignItems: "center",
          position: "relative",
          minHeight: "100vh",
          //   backgroundImage: "url(/bg2.png)",
          backgroundSize: isMobile ? "cover" : "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {getStepContent(activeStep)}
      </Box>
      <LoadingOverlay open={isLoading} />
    </Box>
  );
}

export default Onboarding;
