import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Switch, Tabs, Tab } from "@mui/material";
import { useSnackbar } from "notistack";
import KeywordIcon from "@mui/icons-material/FindInPage";
import MatchIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, loginSuccess } from "../../redux/actions/user";
import io from "socket.io-client";
import { API_URL } from "../../utils/constants";
import axios from "axios";
import CommentDetailCard from "./CommentDetailCard";
import DashboardCard from "./DashboardCard";
import KeywordManagementCard from "./KeywordManagementCard";
import LoadingOverlay from "../../components/LoadingOverlay";
import KeywordBarGraph from "./KeywordBarGraph";

const DAILY_MATCH_LIMITS = {
  starter: 100,
  basic: 200,
  professional: 1000,
  business: Infinity,
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(fetchUser);
  const [darkMode, setDarkMode] = useState(false);

  const [commentDetails, setCommentDetails] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [sortOrder, setSortOrder] = useState("time");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const keywordMatches = user.totalMatches || 0;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const monitoredKeywords =
    user && user.trackingParameters && user.trackingParameters.keywords
      ? Object.keys(user.trackingParameters.keywords).length
      : 0;

  const fetchCommentDetails = useCallback(async () => {
    try {
      const params = new URLSearchParams({
        keyword: filterKeyword,
        sort: sortOrder,
        page: currentPage,
        limit: 10,
      }).toString();
      const response = await axios.get(
        `${API_URL}/api/v0/users/comments?${params}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setCommentDetails(response.data.comments);
      setCurrentPage(currentPage ? currentPage : 1);
      setTotalPages(response.data.pages);
    } catch (error) {
      console.error("Error fetching comment details:", error);
    }
  }, [user.token, filterKeyword, sortOrder, currentPage]);

  const fetchUpdatedUser = useCallback(async () => {
    const response = await axios.get(`${API_URL}/api/v0/users`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      },
    });
    dispatch(loginSuccess(response.data));
  }, [dispatch, user.token]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const updateUserKeywords = async (updatedKeywords) => {
    setIsLoading(true);
    const updatedUser = {
      trackingParameters: {
        ...user.trackingParameters,
        keywords: updatedKeywords,
      },
    };

    try {
      const response = await axios.put(
        `${API_URL}/api/v0/users/`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        dispatch(loginSuccess(response.data.user));
        enqueueSnackbar("Keywords updated successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
        fetchCommentDetails();

        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Keyword update failed: " + error.message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCommentDetails();

    const socket = io(`${API_URL}`, {
      query: { token: user.token },
    });

    socket.on("keywordMatch", (data) => {
      fetchUpdatedUser();
      fetchCommentDetails();

      const dailyLimit =
        DAILY_MATCH_LIMITS[user.subscription] || DAILY_MATCH_LIMITS.starter;
      if (user.dailyMatches.count < dailyLimit) {
        enqueueSnackbar(`New keyword match: ${data.keyword}`, {
          variant: "info",
          autoHideDuration: 2500,
        });
      }
    });

    return () => socket.disconnect();
  }, [
    fetchCommentDetails,
    fetchUpdatedUser,
    filterKeyword,
    user.token,
    enqueueSnackbar,
    user.dailyMatches.count,
    user.subscription,
  ]);

  const handleDarkModeChange = (event) => {
    setDarkMode(event.target.checked);
  };

  // Define dark and light mode styles
  const darkModeStyles = {
    backgroundColor: "#424242",
    color: "#ffffff",
  };

  const lightModeStyles = {
    backgroundColor: "#f0f0f0",
    color: "#000000",
  };

  const cardStyles = {
    border: "1px solid #007FFF", // modern blue outline
    borderRadius: "8px", // optional: for rounded corners
    backgroundColor: "#4e4e4e", // softer shade of black
    color: "#ffffff",
  };

  return (
    <Box
      sx={{ flexGrow: 1, p: 3, minHeight: "100vh" }}
      style={darkMode ? darkModeStyles : lightModeStyles}
    >
      {process.env.REACT_APP_ENABLE_DARK_MODE && (
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Light</Grid>
                <Grid item>
                  <Switch checked={darkMode} onChange={handleDarkModeChange} />
                </Grid>
                <Grid item>Dark</Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <DashboardCard
          icon={<MatchIcon color="primary" />}
          title="Total Matches all time"
          content={keywordMatches}
        />
        <DashboardCard
          icon={<KeywordIcon color="secondary" />}
          title="Total Matches Today"
          content={user.dailyMatches.count}
        />
        <DashboardCard
          icon={<KeywordIcon color="secondary" />}
          title="Monitored Keywords"
          content={monitoredKeywords}
        />
        <Grid item xs={12} sm={12} md={12}>
          <KeywordManagementCard
            user={{ ...user }}
            updateUserKeywords={updateUserKeywords}
            monitoredKeywords={monitoredKeywords}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, ...(darkMode ? cardStyles : {}) }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="Real-Time Analysis" />
              <Tab label="Comment Details" />
            </Tabs>
            {activeTab === 0 && (
              <Box>
                <Typography variant="h5" component="h2" gutterBottom>
                  Real-Time Analysis
                </Typography>
                <KeywordBarGraph
                  trackingParameters={user.trackingParameters}
                  monitoredKeywords={monitoredKeywords}
                  darkMode={darkMode}
                  userComments={user.matchedComments}
                />
              </Box>
            )}
            {activeTab === 1 && (
              <CommentDetailCard
                commentDetails={commentDetails}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                user={user}
                filterKeyword={filterKeyword}
                setFilterKeyword={setFilterKeyword}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                monitoredKeywords={monitoredKeywords}
                isLoading={isLoading}
              />
            )}
          </Paper>
        </Grid>

        {/* <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 2,
              }}
            >
              <ActivityIcon color="action" sx={{ fontSize: 40 }} />
            </Box>
            <Typography variant="h5" component="h3" gutterBottom>
              Recent Activity
            </Typography>
            <Divider />
            <List>
              {monitoredKeywords === 0 ? (
                <Typography sx={{ textAlign: "center", color: "#666" }}>
                  No keywords available.
                </Typography>
              ) : (
                Object.keys(user.trackingParameters.keywords).map(
                  (activity, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={activity}
                        secondary={`Matches: ${user.trackingParameters.keywords[activity].count}`}
                      />
                    </ListItem>
                  )
                )
              )}
            </List>
          </Paper>
        </Grid> */}
      </Grid>

      <LoadingOverlay open={isLoading} />
    </Box>
  );
};

export default Dashboard;
