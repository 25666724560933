import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { API_URL } from "../../utils/constants";
import { loginSuccess } from "../../redux/actions/user";
import moment from "moment";
import LoadingOverlay from "../../components/LoadingOverlay";

function Login() {
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleBack = () => {
    navigate("/");
  };

  const canLogin = () =>
    validateEmail(loginInfo.email) && loginInfo.password.length > 0;

  const handleLogin = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(`${API_URL}/api/v0/users/login`, {
        username: loginInfo.email,
        password: loginInfo.password,
      });
      const user = response.data.user;

      const currentDate = moment();
      const periodEndDate = moment(user.currentPeriodEnd);

      // TODO update to redirect to renew page
      if (user && !user.paid && user.status === "inactive") {
        enqueueSnackbar(
          "You do not currently have an active plan. Please renew to access the dashboard.",
          {
            variant: "error",
            autoHideDuration: 5000,
          }
        );
        setIsLoading(false);
      } else if (
        user &&
        user.status === "pending_cancellation" &&
        currentDate.isAfter(periodEndDate)
      ) {
        enqueueSnackbar(
          "Your subscription period has ended. Please renew to access the dashboard.",
          {
            variant: "warning",
            autoHideDuration: 5000,
          }
        );
        setIsLoading(false);
      } else if (user && user.status === "pending_cancellation") {
        enqueueSnackbar(
          `Your subscription has been cancelled. You will not be able to access the dashboard after ${moment(
            user.currentPeriodEnd
          ).format("MMMM DD, YYYY")}.`,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        setIsLoading(false);

        dispatch(loginSuccess(response.data.user));
        navigate("/dashboard");
      } else {
        dispatch(loginSuccess(response.data.user));
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Login failed: Are you sure you have an account?", {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5", // background color
        mt: 20,
      }}
    >
      <Box
        component="img"
        sx={{
          height: 250,
          borderRadius: "50%",
          mb: 5,
        }}
        alt="Key Sentry Logo"
        src="/logo2.png"
      />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          mx: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="h5" gutterBottom align="center">
          Login
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter" && canLogin()) {
              e.preventDefault();
              handleLogin();
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                name="email"
                value={loginInfo.email}
                onChange={handleChange}
                error={
                  loginInfo.email !== "" && !validateEmail(loginInfo.email)
                }
                helperText={
                  loginInfo.email !== "" && !validateEmail(loginInfo.email)
                    ? "Enter a valid email"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={loginInfo.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" color="primary" onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={!canLogin()}
            >
              Login
            </Button>
          </Box>
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate("/forgot-password")}
            sx={{ alignSelf: "center" }}
          >
            Forgot Password?
          </Button>
        </Box>
      </Paper>
      <LoadingOverlay open={isLoading} />
    </Box>
  );
}

export default Login;
