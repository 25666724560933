import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Grid,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  BASIC_KEYWORD_LIMIT,
  MONEY_BACK_GUARENTEE_DAYS,
  PLANS,
  PROFESSIONAL_KEYWORD_LIMIT,
  STARTER_KEYWORD_LIMIT,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const PaymentPlanOptions = ({
  handleBack,
  handleNext,
  selectedPlan,
  setSelectedPlan,
  billingCycle,
  setBillingCycle,
  selectedKeywordsLength,
}) => {
  const [recommendationMessage, setRecommendationMessage] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();

  const handleSelectPlan = useCallback(
    (planId) => {
      if (planId === "enterprise") {
        navigate("/contact");
      }
      setSelectedPlan(planId);
    },
    [navigate, setSelectedPlan]
  );

  useEffect(() => {
    if (selectedKeywordsLength <= BASIC_KEYWORD_LIMIT) {
      handleSelectPlan("basic");
      setRecommendationMessage(
        "We recommend the Basic Plan for your needs based on the number of selected keywords."
      );
    } else if (selectedKeywordsLength <= PROFESSIONAL_KEYWORD_LIMIT) {
      handleSelectPlan("professional");
      setRecommendationMessage(
        "We recommend the Professional Plan for your needs based on the number of selected keywords."
      );
    } else {
      handleSelectPlan("business");
      setRecommendationMessage(
        "We recommend the Business Plan for your needs based on the number of selected keywords."
      );
    }
  }, [selectedKeywordsLength, handleSelectPlan]);

  const isDisabled = () => {
    if (selectedKeywordsLength <= STARTER_KEYWORD_LIMIT) {
      return false;
    } else if (selectedKeywordsLength <= BASIC_KEYWORD_LIMIT) {
      return false;
    } else if (selectedKeywordsLength <= PROFESSIONAL_KEYWORD_LIMIT) {
      return selectedPlan === "basic" || selectedPlan === "starter";
    } else {
      return selectedPlan !== "business";
    }
  };

  const handleBillingCycleChange = (event, newCycle) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "90%", mx: "auto", px: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ mt: 4, fontWeight: "bold" }}>
        Choose your edition
      </Typography>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <ToggleButtonGroup
          color="primary"
          value={billingCycle}
          exclusive
          onChange={handleBillingCycleChange}
        >
          <ToggleButton value="monthly">Monthly Billing</ToggleButton>
          <ToggleButton value="annual">Annual Billing - Save 33%</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {recommendationMessage && (
        <Typography
          variant="subtitle1"
          sx={{
            mt: 2,
            textAlign: "center",
            color: theme.palette.primary.dark,
            mb: 2,
          }}
        >
          {recommendationMessage}
        </Typography>
      )}
      <Grid container spacing={2} justifyContent="center">
        {PLANS.map((plan) => (
          <Grid item key={plan.id} xs={12} sm={6} md={4} lg={3}>
            <Card
              variant="outlined"
              onClick={() => handleSelectPlan(plan.id)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: 500,
                boxShadow: theme.shadows[3],
                borderRadius: 3,
                backgroundColor: theme.palette.grey[200],
                "&:hover": {
                  boxShadow: theme.shadows[10],
                },
                cursor: "pointer",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: "bold", mb: 2 }}
                >
                  {plan.title}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: "line-through",
                    color: theme.palette.grey[500],
                  }}
                >
                  {billingCycle === "monthly"
                    ? plan.strikedPrice
                    : plan.strikedAnnualPrice}
                  {plan.currency}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
                >
                  {billingCycle === "monthly" ? plan.price : plan.annualPrice}{" "}
                  {plan.currency}
                </Typography>
                <CardActions>
                  <Button
                    size="large"
                    variant={
                      selectedPlan === plan.id ? "contained" : "outlined"
                    }
                    onClick={() => handleSelectPlan(plan.id)}
                    color={selectedPlan === plan.id ? "primary" : "inherit"}
                    fullWidth
                    sx={{ py: 1.5, borderRadius: 3, fontWeight: "bold" }}
                  >
                    Get started
                  </Button>
                </CardActions>
                {plan.mostPopular && (
                  <Typography
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: "#ffffff",
                      display: "inline-block",
                      py: 0.5,
                      px: 2,
                      borderRadius: "4px",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                    }}
                  >
                    MOST POPULAR
                  </Typography>
                )}
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {plan.features.map((feature, index) => (
                    <React.Fragment key={index}>
                      <Typography
                        component="li"
                        sx={{ my: 0.5, textAlign: "left" }}
                      >
                        {feature}
                      </Typography>
                      {index < plan.features.length - 1 && <Divider />}{" "}
                      {/* Don't add a divider after the last item */}
                    </React.Fragment>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="body1"
        sx={{ mt: 4, textAlign: "center", color: theme.palette.grey[600] }}
      >
        Every subscription comes with a {MONEY_BACK_GUARENTEE_DAYS} days money
        back guarantee!
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={isDisabled()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentPlanOptions;
