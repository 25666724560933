const privacy = `
1. Introduction

This Privacy Policy outlines how Key Sentry collects, uses, stores, and protects the personal information of its users.

2. Information Collection

We collect information you provide directly to us, including your email, full name, and keywords tracked.
When you subscribe to our service, we collect your subscription type, total matches, Stripe customer ID, and Stripe subscription ID.

3. Use of Information

The information collected is used to provide and improve our services, process payments, and communicate with you.
We may also use the information for analytics, service improvement, and marketing purposes, subject to your consent where required.

4. Data Storage and Security

We implement a variety of security measures to maintain the safety of your personal information.
Your personal data is stored on secure servers and is only accessible by a limited number of persons.

5. Sharing of Information

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.

6. Stripe Payments

We use Stripe for processing payments. We do not store or collect your payment card details. That information is provided directly to Stripe, whose use of your personal information is governed by their Privacy Policy.

7. User Rights

You have the right to access, correct, or delete your personal information. Please contact us to make such requests.

8. Changes to Our Privacy Policy

We may update this privacy policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

9. Contact Information

If you have any questions about this Privacy Policy, please contact us at info@keysentry.io.

`;
module.exports = {
  privacy,
};
