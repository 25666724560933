import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import RedditIcon from "@mui/icons-material/Reddit";
// import TwitterIcon from "@mui/icons-material/Twitter";

function KeywordsDisplay({
  handleNext,
  selectedKeywords,
  setSelectedKeywords,
  trackingParameters,
  setTrackingParameters,
  selectedPlatforms,
  setSelectedPlatforms,
}) {
  const [newKeyword, setNewKeyword] = useState("");
  // const [ignoreKeyword, setIgnoreKeyword] = useState("");

  const onKeywordSelect = (keyword, type) => {
    const isKeywordSelected = selectedKeywords[type].includes(keyword);
    setSelectedKeywords((prev) => ({
      ...prev,
      [type]: isKeywordSelected
        ? prev[type].filter((k) => k !== keyword)
        : [...prev[type], keyword],
    }));
  };

  const isSelected = (keyword, type) =>
    selectedKeywords[type].includes(keyword);

  const togglePlatformSelection = (platform) => {
    setSelectedPlatforms((prev) =>
      prev.includes(platform)
        ? prev.filter((p) => p !== platform)
        : [...prev, platform]
    );
  };

  const isPlatformSelected = (platform) => selectedPlatforms.includes(platform);
  const totalSelectedKeywords = selectedKeywords.keywords.length;

  const getSubscriptionTier = () => {
    const keywordCount = totalSelectedKeywords;
    if (keywordCount === 1) return "Starter/Basic (1 keyword)";
    if (keywordCount > 1 && keywordCount <= 5)
      return "Professional (1-5 keywords)";
    if (keywordCount > 5) return "Business (5+ keywords)";
    return "Select keywords to see subscription tier";
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "90%", mx: "auto", px: 2 }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", mb: 2, color: "#000", mt: 5 }}
      >
        Select Keywords to Track
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Selected Keywords: {totalSelectedKeywords}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4, color: "secondary.main" }}>
        Subscription Tier: {getSubscriptionTier()}
      </Typography>
      {trackingParameters.keywords.length > 0 ? (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          flexWrap="wrap"
        >
          {trackingParameters.keywords.map((keyword) => (
            <Chip
              key={keyword}
              label={keyword}
              onClick={() => onKeywordSelect(keyword, "keywords")}
              variant={isSelected(keyword, "keywords") ? "filled" : "outlined"}
              sx={{
                "&&": {
                  mt: 2,
                },
                ...(isSelected(keyword, "keywords")
                  ? { backgroundColor: "#4caf50", color: "white" }
                  : {
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      color: "#000",
                    }),
              }}
            />
          ))}
        </Stack>
      ) : (
        <Typography sx={{ color: "#666" }}>
          No suggested keywords available.
        </Typography>
      )}
      <Box sx={{ mt: 3, mb: 3, textAlign: "center" }}>
        <TextField
          label="Add New Keyword"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          variant="outlined"
          sx={{
            mr: 2,
            ".MuiOutlinedInput-root": { borderRadius: 2 },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (newKeyword) {
              onKeywordSelect(newKeyword, "keywords");
              setTrackingParameters((prevParams) => ({
                ...prevParams,
                keywords: [newKeyword, ...prevParams.keywords],
              }));
              setNewKeyword("");
            }
          }}
          sx={{
            height: 54,
            marginTop: {
              sm: 0,
              xs: 3,
            },
          }}
        >
          Add Keyword
        </Button>
      </Box>
      {/* <Typography
        variant="h6"
        sx={{ fontWeight: "bold", mt: 4, mb: 2, color: "#000" }}
      >
        Ignore Keywords
      </Typography>
      {trackingParameters.ignoreKeywords.length > 0 ? (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          flexWrap="wrap"
        >
          {trackingParameters.ignoreKeywords.map((ignoreKeyword) => (
            <Chip
              key={ignoreKeyword}
              label={ignoreKeyword}
              onClick={() => onKeywordSelect(ignoreKeyword, "ignoreKeywords")}
              variant={
                isSelected(ignoreKeyword, "ignoreKeywords")
                  ? "filled"
                  : "outlined"
              }
              sx={{
                "&&": {
                  mt: 2,
                },
                ...(isSelected(ignoreKeyword, "ignoreKeywords")
                  ? { backgroundColor: "#f44336", color: "white" }
                  : {
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      color: "#000",
                    }),
              }}
              color="secondary"
            />
          ))}
        </Stack>
      ) : (
        <Typography sx={{ color: "#666" }}>
          No suggested ignore keywords available.
        </Typography>
      )}
      <Box sx={{ mt: 3, mb: 3, textAlign: "center" }}>
        <TextField
          label="Add Ignore Keyword"
          value={ignoreKeyword}
          onChange={(e) => setIgnoreKeyword(e.target.value)}
          variant="outlined"
          sx={{
            mr: 2,
            ".MuiOutlinedInput-root": { borderRadius: 2 },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (ignoreKeyword) {
              onKeywordSelect(ignoreKeyword, "ignoreKeywords");
              setTrackingParameters((prevParams) => ({
                ...prevParams,
                ignoreKeywords: [ignoreKeyword, ...prevParams.ignoreKeywords],
              }));
              setNewKeyword("");
            }
          }}
          sx={{
            height: 54,
            marginTop: {
              sm: 0,
              xs: 3,
            },
          }}
        >
          Add Ignore Keyword
        </Button>
      </Box> */}
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", mb: 2, color: "#000" }}
        >
          Select Platforms
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          flexWrap="wrap"
        >
          <Chip
            icon={<RedditIcon style={{ color: "red" }} />}
            label="Reddit"
            onClick={() => togglePlatformSelection("Reddit")}
            color={isPlatformSelected("Reddit") ? "primary" : "default"}
            variant={isPlatformSelected("Reddit") ? "filled" : "outlined"}
            sx={{ height: 40, fontSize: "1rem" }}
          />
          {/* <Chip
            icon={<TwitterIcon />}
            label="Twitter"
            onClick={() => togglePlatformSelection("Twitter")}
            color={isPlatformSelected("Twitter") ? "primary" : "default"}
            variant={isPlatformSelected("Twitter") ? "filled" : "outlined"}
          /> */}
          {/* Add more platforms as needed */}
        </Stack>
      </Box>
      <Typography sx={{ color: "#666" }}>
        You can change these later.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: 4,
        }}
      >
        <Tooltip
          title="At least one keyword must be selected"
          arrow
          disableHoverListener={totalSelectedKeywords > 0}
        >
          <span>
            {/* Tooltip requires a child element like span to work correctly */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={totalSelectedKeywords === 0}
            >
              Next
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default KeywordsDisplay;
