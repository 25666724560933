import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
  Grid,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";

import {
  API_URL,
  BASIC_KEYWORD_LIMIT,
  MONEY_BACK_GUARENTEE_DAYS,
  PLANS,
  PROFESSIONAL_KEYWORD_LIMIT,
  STARTER_KEYWORD_LIMIT,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, loginSuccess } from "../../redux/actions/user";
import axios from "axios";
import LoadingOverlay from "../../components/LoadingOverlay";

const UpgradePaymentPlanOptions = () => {
  const user = useSelector(fetchUser);
  const dispatch = useDispatch();
  const currentPlan = user ? user.subscription : "professional";
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(
    user ? user.subscription : "professional"
  );
  const [billingCycle, setBillingCycle] = useState(
    user ? user.subscriptionPeriod : "monthly"
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate(-1);
  };
  const handleNext = async () => {
    if (isDisabled()) {
      return;
    }
    setOpenConfirmDialog(true);
  };

  const handleConfirmUpgrade = async () => {
    setIsLoading(true);
    setOpenConfirmDialog(false);
    try {
      const response = await axios.put(
        `${API_URL}/api/v0/stripe`,
        {
          newPlan: selectedPlan,
          billingCycle: billingCycle,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);
      enqueueSnackbar("Your subscription was updated successfully", {
        variant: "success",
        autoHideDuration: 5000,
      });
      dispatch(loginSuccess(response.data.user));
      navigate("/settings");
    } catch (err) {
      enqueueSnackbar(
        "There was an error changing your subscription. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 5000,
        }
      );
      setIsLoading(false);
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const selectedKeywordsLength =
    user && user.trackingParameters && user.trackingParameters.keywords
      ? Object.keys(user.trackingParameters.keywords).length
      : 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const handleSelectPlan = useCallback(
    (planId) => {
      if (planId === "enterprise") {
        navigate("/contact");
      }
      setSelectedPlan(planId);
    },
    [navigate, setSelectedPlan]
  );

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  });

  const isDisabled = () => {
    if (
      user &&
      selectedPlan === currentPlan &&
      billingCycle === user.subscriptionPeriod
    ) {
      return true;
    }

    if (selectedKeywordsLength <= STARTER_KEYWORD_LIMIT) {
      return false;
    } else if (selectedKeywordsLength <= BASIC_KEYWORD_LIMIT) {
      return false;
    } else if (selectedKeywordsLength <= PROFESSIONAL_KEYWORD_LIMIT) {
      return selectedPlan === "basic" || selectedPlan === "starter";
    } else {
      return selectedPlan !== "business";
    }
  };

  const handleBillingCycleChange = (event, newCycle) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        color: "#000",
      }}
    >
      <Box sx={{ flexGrow: 1, maxWidth: "90%", mx: "auto", px: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mt: 4, fontWeight: "bold" }}
        >
          Change your plan
        </Typography>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <ToggleButtonGroup
            color="primary"
            value={billingCycle}
            exclusive
            onChange={handleBillingCycleChange}
          >
            <ToggleButton value="monthly">Monthly Billing</ToggleButton>
            <ToggleButton value="annual">
              Annual Billing - Save 33%
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Grid container spacing={2} justifyContent="center">
          {PLANS.map((plan) => (
            <Grid item key={plan.id} xs={12} sm={6} md={4} lg={3}>
              <Card
                variant="outlined"
                onClick={() => handleSelectPlan(plan.id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: 500,
                  boxShadow: theme.shadows[3],
                  borderRadius: 3,
                  backgroundColor: theme.palette.grey[200],
                  "&:hover": {
                    boxShadow: theme.shadows[10],
                  },
                  cursor: "pointer",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {billingCycle === "monthly" ? plan.price : plan.annualPrice}{" "}
                    {plan.currency}
                  </Typography>
                  <CardActions>
                    <Button
                      size="large"
                      variant={
                        selectedPlan === plan.id ? "contained" : "outlined"
                      }
                      onClick={() => handleSelectPlan(plan.id)}
                      color={selectedPlan === plan.id ? "primary" : "inherit"}
                      fullWidth
                      sx={{ py: 1.5, borderRadius: 3, fontWeight: "bold" }}
                    >
                      Get started
                    </Button>
                  </CardActions>
                  {plan.mostPopular && (
                    <Typography
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: "#ffffff",
                        display: "inline-block",
                        py: 0.5,
                        px: 2,
                        borderRadius: "4px",
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                      }}
                    >
                      MOST POPULAR
                    </Typography>
                  )}
                  <ul style={{ listStyle: "none", padding: 0 }}>
                    {plan.features.map((feature, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          component="li"
                          sx={{ my: 0.5, textAlign: "left" }}
                        >
                          {feature}
                        </Typography>
                        {index < plan.features.length - 1 && <Divider />}{" "}
                        {/* Don't add a divider after the last item */}
                      </React.Fragment>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="body1"
          sx={{ mt: 4, textAlign: "center", color: theme.palette.grey[600] }}
        >
          Every subscription comes with a {MONEY_BACK_GUARENTEE_DAYS} days money
          back guarantee!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button variant="outlined" color="primary" onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isDisabled()}
          >
            Confirm
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Confirm Plan Upgrade"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to upgrade to the{" "}
            <strong>{selectedPlan}</strong> plan with{" "}
            <strong>{billingCycle}</strong> billing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpgrade} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay open={isLoading} />
    </Box>
  );
};

export default UpgradePaymentPlanOptions;
