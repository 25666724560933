import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { API_URL } from "../../utils/constants";
import { fetchUser, loginSuccess, logout } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LoadingOverlay from "../../components/LoadingOverlay";

function Settings() {
  const navigate = useNavigate();
  const user = useSelector(fetchUser);
  const [isLoading, setIsLoading] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: user ? user.email : "",
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    newPassword: "",
    subscription: user ? user.subscription : "null", // Assuming subscription is part of user object
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const confirmCancellation = () => {
    handleSubscriptionChange("cancel");
    handleDialogClose();
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    return re.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    if (userInfo.newPassword && !validatePassword(userInfo.newPassword)) {
      enqueueSnackbar("New password does not meet the required criteria", {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);

      return;
    } else if (userInfo.newPassword && !userInfo.oldPassword) {
      enqueueSnackbar("Old password required", {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);

      return;
    }

    try {
      const response = await axios.put(`${API_URL}/api/v0/users/`, userInfo, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        dispatch(loginSuccess({ ...user, ...userInfo }));
        enqueueSnackbar("User information updated successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setEditMode(false);
        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Update failed: " + error.message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);
    }
  };

  const handleSubscriptionChange = async (action) => {
    setIsLoading(true);

    if (action === "cancel") {
      const response = await axios.delete(`${API_URL}/api/v0/stripe`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      setIsLoading(false);

      if (response.data.effectiveNow) {
        navigate("/");
        dispatch(logout());
      } else {
        dispatch(loginSuccess(response.data.user));
        setIsLoading(false);
      }
    } else if (action === "upgrade") {
      try {
        if (
          user &&
          user.currentPeriodEnd &&
          user.status === "pending_cancellation"
        ) {
          const response = await axios.get(`${API_URL}/api/v0/stripe/renew`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          dispatch(loginSuccess(response.data.user));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          navigate("/change-plan");
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const response = await axios.delete(`${API_URL}/api/v0/users/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        enqueueSnackbar("User deleted successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
        handleDeleteDialogClose();
        setIsLoading(false);

        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      enqueueSnackbar("Error deleting user: " + error.message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5",
        mt: 20,
      }}
    >
      <Paper
        elevation={3}
        sx={{ p: 4, maxWidth: 400, mx: "auto", bgcolor: "background.paper" }}
      >
        <Typography variant="h5" gutterBottom align="center">
          Settings
        </Typography>
        <FormControlLabel
          sx={{ mb: 3 }}
          control={
            <Switch
              checked={editMode}
              onChange={() => setEditMode(!editMode)}
            />
          }
          label="Edit Mode"
        />
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={userInfo.email}
                onChange={handleChange}
                disabled={!editMode}
                error={
                  !/\S+@\S+\.\S+/.test(userInfo.email) && userInfo.email !== ""
                }
                helperText={
                  !/\S+@\S+\.\S+/.test(userInfo.email) && userInfo.email !== ""
                    ? "Please enter a valid email"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleChange}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleChange}
                disabled={!editMode}
              />
            </Grid>
            {editMode && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    value={userInfo.oldPassword}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="New Password"
                    name="newPassword"
                    type="password"
                    value={userInfo.newPassword}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {editMode && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
              >
                Update Information
              </Button>
            </Box>
          )}
          <Typography variant="h6" gutterBottom align="center" sx={{ mt: 4 }}>
            Your Subscription: {user && capitalize(user.subscription)}{" "}
            {user &&
              user.currentPeriodEnd &&
              user.status === "pending_cancellation" &&
              `(Expires on ${moment(user.currentPeriodEnd).format(
                "MMMM DD, YYYY"
              )})`}
          </Typography>

          <Typography variant="h6" gutterBottom align="center" sx={{ mt: 4 }}>
            Your Subscription Period:{" "}
            {user && capitalize(user.subscriptionPeriod)}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            <Button onClick={() => handleSubscriptionChange("upgrade")}>
              {user && user.status === "active" ? "Change Plan" : "Renew Plan"}
            </Button>
            {user && user.status === "active" && (
              <Button onClick={handleDialogOpen}>Cancel Plan</Button>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteDialogOpen}
              sx={{ ml: 2 }}
            >
              Delete Account
            </Button>
          </Box>

          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Subscription Cancellation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel your subscription? This action
                cannot be undone.
              </DialogContentText>
              <DialogContentText>Please note:</DialogContentText>
              <ul>
                <li>
                  If you are within the 45-day money-back guarantee period, your
                  account will become inactive immediately upon cancellation,
                  and a refund will be processed.
                </li>
                <li>
                  If you are past the 45-day period, your subscription will
                  remain active until the end of your current billing cycle,
                  after which it will not renew.
                </li>
              </ul>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                No
              </Button>
              <Button onClick={confirmCancellation} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDeleteDialog}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">
              {"Confirm Account Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete your account? This action cannot
                be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                No
              </Button>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes, Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Paper>
      <LoadingOverlay open={isLoading} />
    </Box>
  );
}

export default Settings;
