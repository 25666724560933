import React from "react";

import { Grid, Paper, Typography } from "@mui/material";

const DashboardCard = ({ icon, title, content }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: 40 } })}
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
      <Typography variant="h6">{content}</Typography>
    </Paper>
  </Grid>
);

export default DashboardCard;
