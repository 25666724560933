import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Box, useTheme } from "@mui/material";

import KeywordsDisplay from "./pages/onboarding/KeywordsDisplay";
import Onboarding from "./pages/onboarding";
import Dashboard from "./pages/dashboard";
import Waitlist from "./pages/LandingPage/Waitlist";
import AboutUsCTA from "./pages/LandingPage/AboutUsCTA";
import Login from "./pages/onboarding/Login";
import Settings from "./pages/settings/Settings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import UpgradePaymentPlanOptions from "./pages/settings/UpgradePaymentPlanOptions";
import LandingPage from "./pages/LandingPage/LandingPage2";
import Header from "./pages/LandingPage/Header";
import { useSelector } from "react-redux";
import { fetchUser } from "./redux/actions/user";
import NotFoundPage from "./pages/NotFound";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Pricing from "./pages/LandingPage/Pricing";

function KeySentryRoutes() {
  const user = useSelector(fetchUser);
  const theme = useTheme();
  const featuresReady = process.env.REACT_APP_FEATURES_READY === "true";

  return (
    <Router>
      <div className="App">
        <Header />
        <Box sx={{ paddingTop: theme.spacing(12) }}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/pricing" element={<Pricing />} />
            {featuresReady && (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route
                  path="/onboarding/keywords"
                  element={<KeywordsDisplay />}
                />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/contact"
                  element={
                    <>
                      <Waitlist />
                      <AboutUsCTA />
                    </>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    user ? (
                      user.confirmedEmail ? (
                        <Dashboard />
                      ) : (
                        <Navigate to="/verify-email" />
                      )
                    ) : (
                      <Navigate to="/404" />
                    )
                  }
                />
                <Route
                  path="/change-plan"
                  element={
                    user ? (
                      user.confirmedEmail ? (
                        <UpgradePaymentPlanOptions />
                      ) : (
                        <Navigate to="/verify-email" />
                      )
                    ) : (
                      <Navigate to="/404" />
                    )
                  }
                />
                <Route
                  path="/settings"
                  element={
                    user ? (
                      user.confirmedEmail ? (
                        <Settings />
                      ) : (
                        <Navigate to="/verify-email" />
                      )
                    ) : (
                      <Navigate to="/404" />
                    )
                  }
                />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/verify-email/" element={<VerifyEmail />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/verify-email/" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />

                {/* Define the route for the NotFoundPage */}
                <Route path="/404" element={<NotFoundPage />} />
                {/* Catch all unknown routes */}
                <Route path="*" element={<NotFoundPage />} />
              </>
            )}
          </Routes>
        </Box>
      </div>
    </Router>
  );
}

export default KeySentryRoutes;
