// ResetPassword.js
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_URL } from "../utils/constants";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams(); // Assuming the URL is /reset-password/:token

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      return;
    }

    try {
      await axios.post(`${API_URL}/api/v0/users/reset-password`, {
        token,
        password,
      });
      enqueueSnackbar(
        "Password has been successfully reset. You can now login with your new password.",
        { variant: "success" }
      );
      navigate("/login");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to reset password. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5",
        mt: 20,
      }}
    >
      <Box
        component="img"
        sx={{
          height: 250,
          borderRadius: "50%",
          mb: 5,
        }}
        alt="Key Sentry Logo"
        src="/logo.png"
      />
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: "auto" }}>
        <Typography variant="h5" gutterBottom align="center">
          Reset Password
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            fullWidth
            label="New Password"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            required
            fullWidth
            label="Confirm New Password"
            type="password"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ResetPassword;
