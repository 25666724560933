import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import { privacy } from "../utils/privacy";

const PrivacyPolicy = () => {
  const privacyParagraphs = privacy
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy for Key Sentry
        </Typography>
        <Typography variant="body1" paragraph>
          Last Updated: January 29, 2024
        </Typography>
        {privacyParagraphs.map((paragraph, index) => (
          <Typography variant="body1" paragraph key={index}>
            {paragraph}
          </Typography>
        ))}
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
