const tos = `Terms of Service for Key Sentry

Last Updated: January 29, 2024

1. Acceptance of Terms

By accessing and using Key Sentry, you agree to be bound by these Terms of Service and all applicable laws and regulations.
2. Description of Service

Key Sentry provides keyword tracking and analysis services on Reddit. Features vary according to the subscribed plan.
3. User Obligations

You agree to use Key Sentry lawfully and ethically.
You must not use the service to track keywords or gather information for any unlawful purpose.
4. Registration and Account Security

Users may be required to register and maintain a secure account.
Personal information must be accurate and up-to-date.
5. Subscription Plans and Payment

Key Sentry offers various subscription plans. Features and pricing are subject to change.
Payments are due according to the selected subscription plan.
6. Cancellation and Refund Policy

Users can cancel their subscription at any time. Refund policies will be as per the selected plan's terms.
7. Intellectual Property Rights

Key Sentry and its original content, features, and functionality are and will remain the exclusive property of Key Sentry Inc.
8. User Data and Privacy

We are committed to protecting the privacy and security of our users' data. Please refer to our Privacy Policy.
9. Limitation of Liability

Key Sentry Inc. is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services.
10. Modifications to Terms of Service

Key Sentry Inc. reserves the right to modify these terms at any time. Continued use of the service after such changes will constitute your consent to such changes.
11. Governing Law

These terms will be governed by and construed in accordance with the laws of BC, Canada.
12. Contact Information

For any questions about these Terms of Service, please contact us at info@keysentry.io.
`;
module.exports = {
  tos,
};
