import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  useScrollTrigger,
  Slide,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, logout } from "../../redux/actions/user";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector(fetchUser);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    handleClose();
    navigate("/login");
  };

  const handleSignup = () => {
    handleClose();
    navigate("/onboarding");
  };

  const handleDashboard = () => {
    handleClose();
    navigate("/dashboard");
  };

  const handleSettings = () => {
    handleClose();
    navigate("/settings");
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    navigate("/");
  };

  const handleClick = (path) => {
    handleClose();
    navigate(`/${path}`);
  };

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        color="default"
        elevation={4}
        sx={{
          bgcolor: "#ffffff",
          color: "#333333",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link
              to={user && user.paid ? "/dashboard" : "/"}
              style={{ textDecoration: "none" }}
            >
              <Box
                component="img"
                sx={{
                  height: 60,
                  mr: 2,
                  borderRadius: "50%",
                }}
                alt="Key Sentry Logo"
                src="/logo2.png"
              />
            </Link>

            <Typography
              variant="h6"
              color="primary"
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              }}
              noWrap
            >
              Key Sentry
            </Typography>
          </Box>
          <Box>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {user && (user.paid || user.status !== "inactive") ? (
                <Box>
                  <MenuItem onClick={handleDashboard}>Dashboard</MenuItem>
                  <MenuItem onClick={handleSettings}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Box>
              ) : (
                <Box>
                  <MenuItem onClick={() => handleClick("pricing")}>
                    Pricing
                  </MenuItem>
                  <MenuItem onClick={handleLogin}>Login</MenuItem>
                  <MenuItem onClick={handleSignup}>Signup</MenuItem>
                </Box>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
