import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
} from "@mui/material";
import moment from "moment";
import LoadingOverlay from "../../components/LoadingOverlay";

const CommentDetailCard = ({
  commentDetails,
  filterKeyword,
  setFilterKeyword,
  sortOrder,
  setSortOrder,
  user,
  totalPages,
  currentPage,
  handlePageChange,
  isLoading,
}) => (
  <Grid item xs={12}>
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h5" component="h3" gutterBottom>
        Comment Details
      </Typography>
      <Divider />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={4} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Keyword</InputLabel>
              <Select
                value={filterKeyword}
                onChange={(e) => setFilterKeyword(e.target.value)}
                label="Filter by Keyword"
              >
                <MenuItem value="">All</MenuItem>
                {user &&
                  user.trackingParameters &&
                  user.trackingParameters.keywords &&
                  Object.keys(user.trackingParameters.keywords).map(
                    (keyword, index) => (
                      <MenuItem key={index} value={keyword}>
                        {keyword}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <FormControl fullWidth>
              <InputLabel>Sort Order</InputLabel>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                label="Sort Order"
              >
                <MenuItem value="time">Newest</MenuItem>
                <MenuItem value="-time">Oldest</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {commentDetails.length === 0 ? (
        <Typography sx={{ textAlign: "center", color: "#666" }}>
          No comments available.
        </Typography>
      ) : (
        <>
          <List>
            {commentDetails.map((detail, index) => (
              <ListItem
                key={index}
                button={!!detail.url}
                onClick={() => {
                  if (detail.url) {
                    window.open(detail.url, "_blank", "noopener,noreferrer");
                  }
                }}
              >
                <ListItemText
                  primary={`Comment by ${detail.author} on ${moment(
                    detail.time
                  ).format("LLL")}`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2">
                        {detail.comment}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        Score: {detail.score}
                      </Typography>

                      {detail.distinguished && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ display: "block" }}
                        >
                          Distinguished: {detail.distinguished}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Paper>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />{" "}
    </Box>
    <LoadingOverlay open={isLoading} />
  </Grid>
);

export default CommentDetailCard;
