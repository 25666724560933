const initialState = {
  user: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: {
          ...action.payload,
          token: state.user?.token ?? action.payload.token,
        },
      };
    case "CONFIRM_EMAIL_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          confirmedEmail: true,
        },
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
