// NotFoundPage.js or similar file
import { Box } from "@mui/material";
import React from "react";

function NotFoundPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#f5f5f5", // background color
        mt: 20,
      }}
    >
      <Box
        component="img"
        sx={{
          height: 250,
          borderRadius: "25%",
        }}
        alt="Key Sentry Logo"
        src="/notfound2.png"
      />
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </Box>
  );
}

export default NotFoundPage;
