import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const faqs = [
    {
      question: "What services does Key Sentry provide?",
      answer:
        "Key Sentry specializes in real-time monitoring and analysis of social media discussions. We help businesses and individuals track mentions of specific keywords, gauge public sentiment, and stay informed about relevant conversations on Reddit.",
    },
    {
      question: "How does Key Sentry track social media activity?",
      answer:
        "Our system uses advanced algorithms to continuously stream and analyze social media comments and posts. When specified keywords are mentioned, we promptly notify you so you can engage with the content or gather insights for your strategy.",
    },
    {
      question: "Is Key Sentry's social monitoring service free?",
      answer:
        "We offer a basic monitoring service for free, which includes tracking a limited number of keywords and receiving timely alerts. For advanced features and more comprehensive monitoring, we provide paid subscription plans.",
    },
    {
      question: "What additional features does the premium service include?",
      answer:
        "The premium version of Key Sentry's monitoring service includes advanced analytics, AI integration, unlimited keyword tracking, historical data access, and a dedicated support team to assist with your monitoring strategy.",
    },
    {
      question: "Does Key Sentry offer sentiment analysis capabilities?",
      answer:
        "Absolutely, Key Sentry leverages advanced artificial intelligence to provide sentiment analysis, which is a feature available exclusively within our premium packages. This powerful tool allows for a deeper understanding of the context and emotion behind the posts and comments monitored.",
    },
    {
      question: "How can I sign up for Key Sentry's services?",
      answer:
        "You can sign up directly on our website. Choose the plan that suits your needs, enter the keywords you want to monitor, and our system will start tracking them immediately.",
    },
    {
      question: "How is data privacy handled by Key Sentry?",
      answer:
        "Protecting your data is our top priority. We adhere to strict privacy policies and ensure that all monitoring is done in compliance with legal standards.",
    },
    {
      question: "How can I provide feedback or suggestions?",
      answer:
        "We highly value user feedback as it helps us improve our services. Please reach out to us through the contact form on our website with any feedback or suggestions you may have.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderFAQs = () => {
    return faqs.map((faq, index) => (
      <Accordion
        key={index}
        onChange={handleChange(`panel${index}`)}
        expanded={expanded === `panel${index}`}
        sx={{
          width: "100%",
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:not(:last-child)": {
            borderBottom: 1,
            borderColor: "divider",
          },
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Typography sx={{ fontWeight: "bold", width: "100%" }}>
            {faq.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{faq.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Box
      sx={{
        minHeight: "65vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        width: "90%",
        margin: "auto",
        marginTop: {
          sm: 0,
          xs: 5,
        },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: 4,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Frequently Asked Questions
      </Typography>
      {renderFAQs()}
    </Box>
  );
}

export default FAQ;
