import React from "react";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import KeySentryRoutes from "./KeySentryRoutes";

import "./App.css";

import { store } from "./redux/store";
const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ThemeProvider theme={theme}>
            <KeySentryRoutes />
          </ThemeProvider>
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
