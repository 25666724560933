import React, { useRef } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

import AboutUsCTA from "./AboutUsCTA";
import FAQ from "./FAQ";
import Waitlist from "./Waitlist";

const LandingPage = () => {
  const waitlistFormRef = useRef(null);

  const homePageContent = (
    <Box>
      <AboutUsCTA
        scrollToContact={() =>
          waitlistFormRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <FAQ />
      <Waitlist ref={waitlistFormRef} />
    </Box>
  );

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Key Sentry: Unlock Insights, Secure Success - Track & Monitor Keywords
          online.
        </title>
        <link rel="canonical" href="https://keysentry.io/" />
        <meta name="description" content="Keyword Monitoring" />
        <meta
          name="description"
          content="Unlock Insights, Secure Success - We Listen to What Matters Most!"
        />
        <meta name="description" content="Listen to social media" />
        <meta name="description" content="Monitor social media" />
        <meta name="description" content="Keyword monitoring" />
      </Helmet>

      {homePageContent}
    </Box>
  );
};

export default LandingPage;
