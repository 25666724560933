import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const KeywordBarGraph = ({
  userComments,
  trackingParameters,
  darkMode,
  monitoredKeywords,
}) => {
  const hasNoData = monitoredKeywords === 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const data = {
    labels: hasNoData ? [] : Object.keys(trackingParameters.keywords),
    datasets: [
      {
        label: "Keyword Counts",
        data: hasNoData
          ? []
          : Object.keys(trackingParameters.keywords).map((k) => {
              return userComments && userComments[k] && userComments[k].length
                ? userComments[k].length
                : 0;
            }),
        backgroundColor: darkMode
          ? "rgba(135, 206, 250, 0.5)"
          : "rgba(54, 162, 235, 0.5)", // Lighter blue in dark mode
        borderColor: darkMode
          ? "rgba(135, 206, 250, 1)"
          : "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: darkMode ? "#E0E0E0" : "#666", // Adjust font color for dark mode
        },
      },
      x: {
        ticks: {
          color: darkMode ? "#E0E0E0" : "#666", // Adjust font color for dark mode
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: darkMode ? "#E0E0E0" : "#666", // Light gray or dark gray based on mode
        },
      },
    },
    layout: {
      padding: isMobile ? 0 : { right: 175, left: 175 },
    },
  };

  return (
    <Box style={{ height: "300px", width: "100%" }}>
      {hasNoData ? (
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ pt: 10, color: "#666" }}
        >
          No monitored keywords to display.
        </Typography>
      ) : (
        <Bar data={data} options={options} />
      )}
    </Box>
  );
};

export default KeywordBarGraph;
