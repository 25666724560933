import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  List,
  ListItem,
  Card,
  CardContent,
  Divider,
  ListItemText,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function ReviewAndConfirm({
  selectedKeywords,
  userInfo,
  selectedPlan,
  handleBack,
  handleConfirm,
}) {
  const theme = useTheme();

  const plans = {
    starter: "Starter",
    free: "Free",
    basic: "Basic",
    professional: "Professional",
    business: "Business",
  };

  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const modifiedHandleConfirm = () => {
    if (!isAgreed) {
      alert("Please agree to the Privacy Policy and Terms of Service.");
      return;
    }
    handleConfirm();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "90%",
        mx: "auto",
        px: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Review and Confirm
      </Typography>

      <Card
        variant="outlined"
        sx={{ my: 4, borderRadius: 5, boxShadow: theme.shadows[3] }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Selected Keywords
          </Typography>
          <List>
            {selectedKeywords.keywords.map((keyword, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={keyword} />
              </ListItem>
            ))}
            <Divider variant="middle" />
            {selectedKeywords.ignoreKeywords.map((keyword, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CancelOutlinedIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={keyword} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ my: 4, borderRadius: 5, boxShadow: theme.shadows[3] }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary={`First Name: ${userInfo.firstName}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Last Name: ${userInfo.lastName}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Email: ${userInfo.email}`} />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ my: 4, borderRadius: 5, boxShadow: theme.shadows[3] }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Selected Plan
          </Typography>
          <Typography variant="subtitle1">{plans[selectedPlan]}</Typography>
        </CardContent>
      </Card>
      <FormControlLabel
        control={
          <Checkbox
            checked={isAgreed}
            onChange={handleCheckboxChange}
            name="agreed"
          />
        }
        label={
          <Typography>
            I have read and agree to the
            <Link href="/privacy-policy" target="_blank">
              {" "}
              Privacy Policy
            </Link>{" "}
            and
            <Link href="/terms-of-service" target="_blank">
              {" "}
              Terms of Service
            </Link>
            .
          </Typography>
        }
      />

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={modifiedHandleConfirm}
          disabled={!isAgreed}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
}

export default ReviewAndConfirm;
