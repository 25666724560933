const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

const PLANS = [
  // {
  //   id: "free",
  //   title: "Free",
  //   price: "$0",
  //   currency: "USD",
  //   features: [
  //     "Track up to 3 keywords",
  //     // "Email alerts",
  //     // "No reporting",
  //     // "1 platform",
  //     "Basic support",
  //   ],
  // },
  {
    id: "starter",
    title: "Starter",
    price: "$4.99",
    annualPrice: "$3.49",
    strikedPrice: "$11.99",
    strikedAnnualPrice: "$10.49",
    currency: "USD/month",
    features: [
      "Track 1 keyword",
      "1 keyword change per day",
      "Broad Spectrum Keyword Tracking",
      "Daily cap of 100 matches",
      "Reddit only",
      // "Email alerts",
      // "Weekly reporting",
      // "2 platforms",
      "Basic support",
    ],
  },
  {
    id: "basic",
    title: "Basic",
    price: "$25.99",
    annualPrice: "$16.99",
    strikedPrice: "$32.99",
    strikedAnnualPrice: "$23.99",
    currency: "USD/month",
    features: [
      "Track 1 keyword",
      "Broad Spectrum Keyword Tracking",
      "Daily cap of 200 matches",
      "Email Alerts (coming soon)",
      "Weekly Reporting (coming soon)",
      "Reddit only (for now)",
      "Basic support",
    ],
  },
  {
    id: "professional",
    title: "Professional",
    price: "$45.99",
    annualPrice: "$29.99",
    strikedPrice: "$52.99",
    strikedAnnualPrice: "$69.99",
    currency: "USD/month",
    features: [
      "Track up to 5 keywords",
      "Broad Spectrum Keyword Tracking",
      "Daily cap of 1000 matches",
      "Customizable Alerts (coming soon)",
      "Daily Reporting (coming soon)",
      "Reddit only (for now)",
      "Professional support",
    ],
    mostPopular: true,
  },
  {
    id: "business",
    title: "Business",
    price: "$59.99",
    annualPrice: "$39.99",
    strikedPrice: "$66.99",
    strikedAnnualPrice: "$46.99",
    currency: "USD/month",
    features: [
      "Track unlimited keywords",
      "AI-Enhanced Precision Tracking",
      "Unlimited daily matches",
      "Customizable Alerts (coming soon)",
      "Hourly Reporting (coming soon)",
      "AI sentiment analysis (coming soon)",
      "Reddit only (for now)",
      "Priority support",
    ],
  },
  // {
  //   id: "enterprise",
  //   title: "Enterprise",
  //   price: "Contact sales",
  //   annualPrice: "Contact sales",
  //   features: [
  //     "Track unlimited keywords",
  //     "AI-Enhanced Precision Tracking",
  //     "Custom alert channels",
  //     "Real-time reporting",
  //     "Dedicated account manager",
  //     "API access",
  //     "24/7 premium support",
  //   ],
  // },
];

const STARTER_KEYWORD_LIMIT = 1;
const BASIC_KEYWORD_LIMIT = 1;
const PROFESSIONAL_KEYWORD_LIMIT = 5;

const MONEY_BACK_GUARENTEE_DAYS = 30;
module.exports = {
  API_URL,
  STARTER_KEYWORD_LIMIT,
  BASIC_KEYWORD_LIMIT,
  PROFESSIONAL_KEYWORD_LIMIT,
  PLANS,
  MONEY_BACK_GUARENTEE_DAYS,
};
